<template>
  <div class="container" id="app" @contextmenu="showContextMenu">

    <!-- $route.meta.keepAlive为true设置缓存 -->
    <keep-alive>
      <router-view style="width: 100%;height: 100%" v-if="$route.meta.keepAlive" />
    </keep-alive>

    <!-- $route.meta.keepAlive为false正常显示 -->
    <router-view style="width: 100%;height: 100%" v-if="!$route.meta.keepAlive" />

  </div>
</template>

<script>
export default {

  methods: {

    // 禁止右键菜单
    showContextMenu(event) {

      /*
      // 阻止默认右键菜单
      event.preventDefault();
      // 获取右键点击的位置
      this.contextMenuPosition.x = event.clientX;
      this.contextMenuPosition.y = event.clientY;
      // 显示右键菜单
      this.contextMenuVisible = true;
      */
    },
  }
}


</script>

<style lang="scss">

body {

  /*--字体--*/
  /*
  font-family: Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, SimSun, sans-serif;
  font-family: Microsoft YaHei, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  */
  font-family: "Helvetica Neue", "open sans", Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
}

</style>
