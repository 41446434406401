import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const ExpiresInKey = 'Admin-Expires-In'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
    return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
    return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
    return Cookies.remove(ExpiresInKey)
}

// 设置表格头文件信息
export function setTable(tableName, colModel) {

    // 返回结果
    let ret = {result: true}

    // 不支持本地存储
    if (!window.localStorage) {
        ret['result'] = false;
        ret['message'] = '不支持本地存储';
        return ret;
    }

    // 期待参数不足
    if (!tableName || !colModel) {
        ret['result'] = false;
        ret['message'] = '期待参数不足';
        return ret;
    }

    window.localStorage.setItem(tableName, JSON.stringify(colModel));

    return ret;
}

// 获取表格头文件信息
export function getTable(tableName) {

    // 返回结果
    let ret = {result: true}

    // 不支持本地存储
    if (!window.localStorage) {
        ret['result'] = false;
        ret['message'] = '不支持本地存储';
        return ret;
    }

    // 表格头文件信息为空
    let colModel = window.localStorage.getItem(tableName);
    if (!colModel) {
        ret['result'] = false;
        ret['message'] = '表格头文件信息为空';
        return ret;
    }

    // 转为JSON对象
    ret['colModel'] = JSON.parse(colModel);

    return ret;
}