import axios from 'axios'
import {Notification, MessageBox, Message, Loading} from 'element-ui'
import {getToken} from '@/utils/auth'

// 错误码，一般指导分流，暂时不用
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {

    // 请求携带TOKEN
    let token = getToken();

    // 让每个请求携带自定义token 请根据实际情况自行修改
    if (token) config.headers['Authorization'] = 'Bearer ' + token

    return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {

        // 二进制数据则直接返回
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }

        // 错误说明，暂时不采用code方式
        if (res.data.result != null && res.data.result == false) {
            let msg = res.data.msg || res.data.message || "操作错误";
            Message({message: msg, type: 'error'})
            return Promise.reject(msg)
        } else {
            return res.data
        }

    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

export default service
