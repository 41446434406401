import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/login/Login.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Login.vue'),
  },
  {
    path: '/init',
    name: 'Init',
    component: () => import('../views/exception/init'),
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/layout/iframe.vue'),
  },
  {
    path: '/manager/showTableMain',
    name: 'ShowTableMain',
    component: () => import('../views/business/showTableMain.vue'),
  },
  {
    path: '/manager/showTableForm',
    name: 'ShowTableForm',
    meta:{
      keepAlive:true
    },
    component: () => import('../views/business/showTableForm.vue'),
  },
  {
    path: '/manager/showTableDetail',
    name: 'ShowTableDetail',
    component: () => import('../views/business/showTableDetail.vue'),
  },
  {
    path: '/manager/showImageDetail',
    name: 'ShowImageDetail',
    component: () => import('../views/business/showImageDetail.vue'),
  },
  {
    path: '/manager/showStatisticsMain',
    name: 'ShowStatisticsMain',
    component: () => import('../views/business/showStatisticsMain.vue'),
  },
  {
    path: '/manager/showSeekForm',
    name: 'ShowSeekForm',
    component: () => import('../components/business/showSeekForm.vue'),
  },
  {
    path: '/manager/updateUser',
    name: 'UpdateUser',
    meta:{
      keepAlive:true
    },
    component: () => import('../views/user/updateUser.vue'),
  },
  {
    path: '/manager/updatePwd',
    name: 'UpdatePwd',
    component: () => import('../views/user/updatePwd.vue'),
  },
  {
    path: '/manager/setTableColumn',
    name: 'SetTableColumn',
    component: () => import('../views/business/setTableColumn.vue'),
  },
  {
    path: '/manager/showFavoritesMain',
    name: 'ShowFavoritesMain',
    component: () => import('../views/business/showFavoritesMain.vue'),
  },
  {
    path: '/manager/echarts',
    name: 'echarts',
    component: () => import('../views/layout/echarts'),
  },
  {
    path: '/manager/showBatchAddForm',
    name: 'echarts',
    component: () => import('../views/developer/showBatchAddForm'),
  },
]

const router = new VueRouter({
  routes
})

export default router
