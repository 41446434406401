import router from './router'
import {Message} from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {getToken} from '@/utils/auth'

import {dynamicRoute} from '@/api/public'

NProgress.configure({showSpinner: false})

const whiteList = ['/login']

// 动态路由控件，功能+表单
export const loadView = (view) => {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/developer/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        return () => import(`@/views/developer/${view}`)
    }
}

// 添加动态路由
dynamicRoute().then(res => {
    for (let key in res) {
        let tableName = res[key];
        let nameArr = new Array('Main', 'Form');

        nameArr.forEach(name => {
            let method = "show" + tableName + name;
            router.addRoute({
                name: method,
                path: "/manager/" + method,
                component: loadView(method),
            });

        });
    }
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
})

router.afterEach(() => {
    NProgress.done()
})
