// 请求接口，是同步还是异步方式？
import request from "@/utils/request";

/*--------------基础通用接口----------------*/

// get方式
export function get(apiUrl) {
    return request({
        url: apiUrl,
        method: 'get'
    })
}

// post方式
export function post(apiUrl, data) {
    return request({
        url: apiUrl,
        method: 'post',
        data: data
    })
}

/*--------------框架接口----------------*/

// 图形校验码
export function getVerify() {
    return get('/getVueVerify');
}

// 登录+含图形码方式
export function login(data) {
    return post('/login2', data);
}

// 退出
export function logout() {
    return get('/manager/quit');
}

// 当前登录用户
export function getLoginUser() {
    return get('/manager/getLoginUser');
}

// 获得配置信息
export function getConfig() {
    return get('/vue/getPlatform');
}

// 左侧功能菜单
export function menu(sortOrder) {
    return get('/vue/menu?sortOrder='+sortOrder);
}

// 功能描述
export function getTableCaption(tableName) {
    return get('/vue/getTableCaption?tableName=' + tableName);
}

// 表单描述
export function getFormModel(tableName) {
    return get('/vue/getFormModel?tableName=' + tableName);
}

// 详情描述
export function getDetailModel(tableName) {
    return get('/vue/getDetailFormModel?tableName=' + tableName);
}

// 密码修改
export function updatePwd(data) {
    return post('/manager/updatePwd', data);
}

// 动态路由
export function dynamicRoute() {
    return get('/vue/dynamicRoute');
}

// 量化统计图
export function echarts() {
    return get('/developer/groupStatistics');
}

/*--------------业务接口----------------*/

// 查询列表
export function list(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/list', data);
}

// 添加
export function add(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/insert', data);
}

// 修改
export function update(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/update', data);
}

// 删除
export function deleteByIds(tableName, ids) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return get('/business/' + tableAlias + '/deleteByIds?ids=' + ids);
}

// 统计信息
export function statistics(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/statistics', data);
}

// 获得实体
export function getEntity(tableName, id) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return get('/business/' + tableAlias + '/getEntity?id=' + id);
}

// 获得详情
export function getDetail(tableName, id) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return get('/business/' + tableAlias + '/detail?id=' + id);
}

// 导出，待调整
export function downExcel(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/downExcel', data);
}

// 导出模板，待调整
export function downExcelTemplate(tableName) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/downExcelTemplate');
}

// 导入，待调整
export function importExcel(tableName, data) {

    // 别名
    let tableAlias = tableName.replace(/_/g, "");
    tableAlias = tableAlias.toLowerCase();

    return post('/business/' + tableAlias + '/importExcel', data);
}


// 批量导入厕所
export function batchAddToilet(data) {

    return post('/mobile/batchAddToilet', data);
}
