import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

// 图形文字CSS
import './static/font-awesome-4.7.0/css/font-awesome.css'

// 路由前后，进度等
import './permission' // permission control

// 请求接口
import request from "@/utils/request";
Vue.prototype.request = request

// 用户引导
import intro from 'intro.js' // introjs库
import 'intro.js/introjs.css' // introjs默认css样式
import 'intro.js/themes/introjs-modern.css' // introjs主题
Vue.prototype.$intro = intro

// 粒子效果
import VueParticles from 'vue-particles'
Vue.use(VueParticles)

// 打印
import Print from 'vue-print-nb'

import './plugins/charts.js'
Vue.use(Print)

// baidu地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap , {
  ak:"DD279b2a90afdf0ae7a3796787a0742e"
})

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
